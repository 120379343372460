import { Divider, Drawer, Modal } from "@mantine/core";

import { useGetDeviceType } from "_common/hooks/global";
import { PrimaryButton }    from "_components/atoms/button";
import Chip                 from "_components/atoms/chips";
import Icon                 from "_components/atoms/icon";
import MobileBottomDrawer   from "_components/atoms/mobileBottomDrawer";
import Typography           from "_components/atoms/typography";
import useStyles            from "_styles/organisms/reportDialogBox";

import { ReportSkeleton } from "./reportSkeleton";

const component = ( {
	setShowDialog,
	showDialog,
	handleReport,
	selected,
	handleSubmit,
	tagList,
	dummyData
} ) => {

	const { classes } = useStyles ( {}, { name: "reportDialogBox" } );
	const text1       = "Your reports make Rooter a better place for everyone.";
	const text2       = "Please select your concern with the content:";

	const deviceType = useGetDeviceType ();

	const isMobile = deviceType === "mobile";
	const title    = (
		<div className = { classes.topHeader }>
			<Typography
				className = { classes.settingsText }
				title     = "Report Post"
				variant   = "h4"
			/>

			<Icon
				className = { classes.crossButton }
				name      = "cross"
				onClick   = { () => setShowDialog ( false ) }
			/>
		</div>
	);
	const body     = (
		<>
			<div className = { classes.contentTextFirst }>
				{text1}
			</div>

			<div className = { classes.contentTextSecond }>
				{text2}
			</div>

			<div className = { classes.reportButtonContainer }>

				{!tagList.length ? (
					<div style = { classes.customContainerStyle }>
						{dummyData.map ( ( _, idx ) => {
							return (
								<ReportSkeleton key = { idx } />
							);
						} )}
					</div>
				) : tagList?.map ( ele => {
					return (
						<Chip key = { ele.id }
							checked = { selected?.id === ele.id }
							className  = { classes.reportButton }
							onChange = { () => handleReport ( ele ) }
						>
							{ele.reasonType}
						</Chip>

					);
				} )}
			</div>
		</>
	);

	const content = (
		isMobile ? null : (
			<>
				{isMobile ? (
					<Drawer.Title>
						{title}
					</Drawer.Title>
				) : (
					<Modal.Title>
						{title}
					</Modal.Title>
				)}

				{
					isMobile ? (
						<Drawer.Body>
							{body}
						</Drawer.Body>
					) : (
						<Modal.Body
							className = { classes.customDialogContent }
						>
							{body}
						</Modal.Body>
					)
				}

				{
					!isMobile ?
						<Divider />
						: null
				}

				<div className = { classes.submitButtonContainer }>

					<PrimaryButton
						className = { selected ? classes.submitButton : classes.submitButtonDeactive }
						disabled  = { !selected }
						onClick   = { handleSubmit }
						size      = { "large" }
						title     = "Submit"
					/>

				</div>
			</>
		)
	);

	if ( isMobile ) {
		return (
			<MobileBottomDrawer
				isOpen  = { showDialog }
				onClose = { () => setShowDialog ( false ) }
			>
				{content}
			</MobileBottomDrawer>
		);
	}

	return (
		<Modal
			centered
			classNames = { {
				content: classes.customDialog, body: classes.modalBody
			} }
			onClose = { () => {
				setShowDialog ( false );
			} }
			opened          = { showDialog }
			p               = "0"
			radius          = { "lg" }
			withCloseButton = { false }

		>
			{content}

		</Modal>
	);

};

export default component;
