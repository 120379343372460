import { useRouter }           from "next/router";
import { useEffect, useState } from "react";

import { storeEvent }                            from "_analytics/index";
import { useGetLoggedIn, useGetProfileId }       from "_common/hooks/auth";
import { useGetDarkMode, useSetLoginPopup }      from "_common/hooks/global";
import { useGetDeviceType }                      from "_common/hooks/global";
import Toast                                     from "_common/toast";
import { LIVESESSION, downloadUrl, isPageRoute } from "_common/utils";
import { useGetModerators, useSetModerators }    from "_components/pages/studio/states";
import { getModeratorsCount }                    from "_components/pages/studio/utils";
import {
	addModeratorAPI,
	deleteLivePost,
	downloadStreamRequest,
	getStreamDownloadStatus,
	removeModeratorAPI
} from "_services/api/broadcast";
import { deleteStaticPost } from "_services/api/feed";

import Presentation from "./presentation";

const component = ( {
	feedId,
	isFollowing,
	userId,
	userName,
	verticalFeedType,
	feedType,
	from,
	updateDeletedPost,
	handleModalClose,
	updateFeedList,
	isModerator,
	toggleErrorModal,
	userProfile,
	toggleModeratorOption,
	toggleDialogInfoBox,
	activeTab,
	toggleDeleteModal,
	shareModalOpen, // share
	shareAnchorEl, // share
	handleClickAway, // share
	handleShareClickOpen, // share
	getSrc, // share other profile
	getDonationSrc, // own profile
	onClickShareModal
} ) => {

	const [ anchorEl, setAnchorEl ]               = useState ( null );
	const [ showDialog, setShowDialog ]           = useState ( false );
	const [ open, setOpen ]                       = useState ( false );
	const [ showShareDialog, setShowShareDialog ] = useState ( false );
	const isLoggedIn                              = useGetLoggedIn ();
	const myProfileId                             = useGetProfileId ();
	const isOwnProfile                            = myProfileId === userId;
	const darkMode                                = useGetDarkMode ();
	const moderatorsList                          = useGetModerators ();
	const moderatorsCount                         = getModeratorsCount ( moderatorsList );
	const [ loading, setLoading ]                 = useState ( false );
	const [ downloadStatus, setDownloadStatus ]   = useState ( {} );
	const [ openDialog, setOpenDialog ]           = useState ( false );
	const setLoginPopup                           = useSetLoginPopup ();
	const setModeratorsList                       = useSetModerators ();

	const deviceType = useGetDeviceType ();
	const isMobile   = deviceType === "mobile";

	const router = useRouter ();

	// close as soon as reel changes or user shifts to new page
	useEffect ( () => {
		setOpen ( false );
	}, [ router.asPath ] );

	const checkPageRoute = page => {
		return isPageRoute ( { router, page } );
	};

	let buttonText     = "";
	let infoModalTitle = "";
	let infoModalText  = "";

	if (
		downloadStatus.status === "NO_DOWNLOAD_REQUESTED" ||
    downloadStatus.status === "FAILED" ||
    downloadStatus.status === "DELETED"
	) {
		buttonText     = "Request to Download";
		infoModalTitle = "Download Request Sent Successfully";
		infoModalText  = "Come back after a few minutes to check the updates";
	} else if ( downloadStatus.status === "PENDING" || downloadStatus.status === "PROCESSING" ) {
		buttonText     = "Download Requested";
		infoModalTitle = "";
		infoModalText  = "";
	} else if ( downloadStatus.status === "COMPLETED" ) {
		buttonText = "Download Now";
	}

	const handleClickOpen = async () => {
		if ( !open ) {

			if ( activeTab === "live" && isLoggedIn ) {
				setLoading ( true );

				const res = await getStreamDownloadStatus ( { broadcastSessionId: parseInt ( feedId ) } );

				if ( res ) {
					setDownloadStatus ( res );
					setLoading ( false );
				}
			}

		}
		setOpen ( previousOpen => !previousOpen );
	};

	const toggleMenuDialog = () => {
		setOpen ( previousOpen => !previousOpen );
	};

	const handleReport = () => {

		if ( !isLoggedIn ) {
			setLoginPopup ( true );
		} else {
			setShowDialog ( true );
			setOpen ( previousOpen => !previousOpen );
		}
	};

	const handleDelete = async () => {
		if ( feedType === LIVESESSION ) {
			let sessionId = feedId;

			try {
				const res = await deleteLivePost ( sessionId );

				if ( res?.deleteSession ) {
					updateDeletedPost ( feedId );
				}

			} catch ( err ) {
				Toast.error ( err.displayMsg );
			}
		} else {
			try {
				const res = await deleteStaticPost ( feedId );

				if ( res?.remove_upload ) {
					updateDeletedPost ( feedId );
					if ( from === "image-modal" ) {
						handleModalClose ();
					}
				}
			} catch ( err ) {
				Toast.error ( err.displayMsg );
			}
		}

	};

	const addModerator = async () => {
		if ( moderatorsCount === 10 ) {
			toggleMenuDialog ();

			return toggleErrorModal ();
		}

		try {

			await addModeratorAPI ( { userIds: [ userId ] } );
			setModeratorsList ( [ ...moderatorsList, userProfile ] );
			toggleModeratorOption ();
			Toast.success ( "Moderator added successfully" );
		} catch ( error ) {
			Toast.info ( error.displayMsg );

		}

	};

	const removeModerator = async () => {
		try {
			await removeModeratorAPI ( { userIds: [ userId ] } );

			const newList = moderatorsList.filter ( el => el.id !== userId );

			setModeratorsList ( newList );
			toggleModeratorOption ();
			Toast.success ( "Moderator removed successfully" );

		} catch ( error ) {
			Toast.info ( error.displayMsg );
		}
	};

	const onRequestToDownload = async () => {
		if ( !isLoggedIn ) return;

		if (
			downloadStatus.status === "NO_DOWNLOAD_REQUESTED" ||
      downloadStatus.status === "FAILED" ||
      downloadStatus.status === "DELETED"
		) {
			try {
				const res = await downloadStreamRequest ( { broadcastSessionId: parseInt ( feedId ) } );

				if ( res )
					setOpenDialog ( true );
				// setOpen ( false );
			} catch ( error ) {
				Toast.error ( error.displayMsg );
				// setOpen ( false );
			}
		} else if ( downloadStatus.status === "PENDING" || downloadStatus.status === "PROCESSING" ) {
			Toast.info ( "Download Request Under Process" );
			// setOpenDialog ( true );
			setOpen ( false );
		} else if ( downloadStatus.status === "COMPLETED" ) {
			// setOpen ( false );
			const link = downloadStatus.downloadURL;

			downloadUrl ( link );
		}
	};

	const handleGAEvent = type => {

		if ( type === "follow" ) {
			setOpen ( open => !open );
		}

		switch ( type ) {
			case "follow": {
				if ( checkPageRoute ( "home" ) ) {
					storeEvent ( "Profile", "Follow", "Home_Feed" );
				}
				if ( checkPageRoute ( "video" ) ) {
					storeEvent ( "Profile", "Follow", "Video" );
				}
				if ( checkPageRoute ( "profile" ) ) {
					storeEvent ( "Profile", "Follow", "Profile" );
				}

				if ( checkPageRoute ( "results" ) ) {
					storeEvent ( "Profile", "Follow", "Search" );
				}

				return;

			}

			case "unfollow": {
				if ( checkPageRoute ( "home" ) ) {
					storeEvent ( "Profile", "Unfollow", "Home_Feed" );
				}
				if ( checkPageRoute ( "video" ) ) {
					storeEvent ( "Profile", "Unfollow", "Video" );
				}
				if ( checkPageRoute ( "profile" ) ) {
					storeEvent ( "Profile", "Unfollow", "Profile" );
				}

				if ( checkPageRoute ( "results" ) ) {
					storeEvent ( "Profile", "Unfollow", "Search" );
				}

				return;
			}

			case "share": {
				if ( checkPageRoute ( "game" ) ) {
					storeEvent ( "Broadcast", "Share", "Game_Card" );
				}
				if ( checkPageRoute ( "live" ) ) {
					storeEvent ( "Broadcast", "Share", "Live_tab" );
				}
				if ( checkPageRoute ( "profile" ) ) {
					storeEvent ( "Broadcast", "Share", "Profile" );
				}

				return;
			}

			default:
				break;
		}
	};

	const toggleShareModal = state => {
		setShowShareDialog ( state );
		onClickShareModal ();
		setOpen ( false );
	};

	return (
		<Presentation
			activeTab = { activeTab }
			addModerator = { addModerator }
			anchorEl = { anchorEl }
			buttonText = { buttonText }
			darkMode = { darkMode }
			downloadStatus = { downloadStatus }
			feedId = { feedId }
			feedType = { feedType }
			from = { from }
			getDonationSrc = { getDonationSrc }
			getSrc = { getSrc }
			handleClickAway = { handleClickAway }
			handleDelete = { handleDelete }
			handleGAEvent = { handleGAEvent }
			handleOpen = { handleClickOpen }
			handleReport = { handleReport }
			handleShareClickOpen = { handleShareClickOpen }
			infoModalText = { infoModalText }
			infoModalTitle = { infoModalTitle }
			isFollowing = { isFollowing }
			isLoggedIn = { isLoggedIn }
			isMobile = { isMobile }
			isModerator = { isModerator }
			isOwnProfile = { isOwnProfile }
			loading = { loading }
			onRequestToDownload = { onRequestToDownload }
			open = { open }
			openDialog = { openDialog }
			removeModerator = { removeModerator }
			setOpen = { setOpen }
			setOpenDialog = { setOpenDialog }
			setShowDialog = { setShowDialog }
			shareAnchorEl = { shareAnchorEl }
			shareModalOpen = { shareModalOpen }
			showDialog = { showDialog }
			showShareDialog = { showShareDialog }
			toggleDeleteModal = { toggleDeleteModal }
			toggleDialogInfoBox = { toggleDialogInfoBox }
			toggleErrorModal = { toggleErrorModal }
			toggleMenuDialog = { toggleMenuDialog }
			toggleShareModal = { toggleShareModal }
			updateFeedList = { updateFeedList }
			userId = { userId }
			userName = { userName }
			userProfileId = { userProfile?.id }
			verticalFeedType = { verticalFeedType }
		/>

	);
};

export default component;
