import { Menu, Skeleton } from "@mantine/core";

import { useSetOpenUserSettingModal } from "_common/hooks/global";
import BackgroundWrapper              from "_components/atoms/backgroundWrapper";
import Icon                           from "_components/atoms/icon";
import InfoModal                      from "_components/atoms/infoModal";
import MobileBottomDrawer             from "_components/atoms/mobileBottomDrawer";
import ToolTip                        from "_components/atoms/tooltip";
import Typography                     from "_components/atoms/typography";
import ConfirmModal                   from "_components/molecules/confirmModal";
import SharePopper                    from "_components/molecules/sharePopper";
import ReportDialogBox                from "_components/organisms/reportDialogBox";
import HoverInfoText                  from "_components/templates/userProfileHeader/components/hoverInfo";
import { DONATION_PAGE }              from "_config/index";
import useStyles                      from "_styles/molecules/ReportFollowModal";

import FollowUser from "../followUser";

const SingleLoader = () => {
	const { classes } = useStyles ();

	return (
		<div className = { `${ classes.menuItem }` }
			style = { { opacity: "0.2", padding: "1rem 0.4rem", borderRadius: "0.8rem" } }
		>
			<Skeleton
				circle
				animate = { false }
				height = { 35 }
			/>

			<Skeleton
				animate = { false }
				className = { classes.rectangularSekleton }
				height = { 25 }
				sytle = { { fontSize: "1.5rem" } }
				width = { 100 }
			/>
		</div>
	);
};

const LoadingState = ( { from, open, status } ) => {
	const { classes } = useStyles ( { from, open, status } );

	return (
		Array ( 2 ).fill ( 1 ).map ( ( el, idx ) => (
			<Menu.Item key = { idx }
				className = { classes.loaderWrapper }
			>
				<SingleLoader />
			</Menu.Item>

		)
		)
	);
};

const component = ( {
	anchorEl,
	open,
	handleOpen,
	toggleMenuDialog,
	handleDelete,
	handleReport,
	setShowDialog,
	showDialog,
	isFollowing,
	userId,
	userName,
	feedId,
	feedType,
	isOwnProfile,
	verticalFeedType,
	updateFeedList,
	addModerator,
	removeModerator,
	from,
	isModerator,
	toggleDialogInfoBox,
	loading,
	onRequestToDownload,
	downloadStatus,
	openDialog,
	setOpenDialog,
	buttonText,
	infoModalText,
	infoModalTitle,
	activeTab,
	isLoggedIn,
	toggleDeleteModal,
	handleGAEvent,
	userProfileId,
	shareModalOpen, // share
	shareAnchorEl, // share
	handleClickAway, // share
	handleShareClickOpen, // share
	getSrc, // share other profile
	getDonationSrc,
	toggleShareModal,
	showShareDialog,
	setOpen,
	isMobile
} ) => {

	const status                  = downloadStatus.status;
	const { classes }             = useStyles ( { from, open, status }, { name: "reportFollowModal" } );
	const toggleUserSettingsPopup = useSetOpenUserSettingModal ();

	const cancelBtn = (
		<div
			className = { classes.menuItem }
			onClick = { () => {
				// handleGAEvent ( "share" );
				toggleMenuDialog ();
			} }
		>
			<Icon
				sameInBothTheme
				className = { classes.svgIcon }
				name = "closeFilled"
			/>

			<Typography
				className = { classes.iconText }
				title = "Cancel"
			/>

		</div>
	);

	const renderOptions = () => {
		if ( from === "polls" ) {
			return (
				<>
					<div
						className = { classes.menuItem }
						onClick = { () => {
							toggleMenuDialog ();
							toggleDeleteModal ();
						} }
					>
						<Icon
							sameInBothTheme
							className = { classes.svgIcon }
							name = "deleteFilled"
						/>

						<Typography
							className = { classes.iconText }
							title = "Delete Poll"
						/>

					</div>

					<div
						className = { classes.menuItem }
						onClick = { () => {
							toggleMenuDialog ();
						} }
					>
						<Icon
							sameInBothTheme
							className = { classes.svgIcon }
							name = "cancelFilled"
						/>

						<Typography
							className = { classes.iconText }
							title = "Cancel"
						/>

					</div>
				</>
			);
		}

		return (
			<>
				{/* Follow option not shown for own profile and not shown in header component of profile */}

				{from === "reel" ? null : from !== "profile-header" && !isOwnProfile ? (
					<div
						className = { classes.menuItem }
					>
						<FollowUser
							feedTop = { false }
							followComponent = { (
								<div
									className = { classes.followComponent }
									onClick = { () => {
										handleGAEvent ( "follow" );
										setOpen ( false );
									} }

								>
									<Icon
										sameInBothTheme
										className = { `${ classes.svgIcon }` }
										name = "followFilled"
									/>

									<Typography
										className = { classes.iconText }
										title = { from === "profile-header" ? "Follow Profile" : "Follow" }
									/>

								</div>
							) }
							fromFeedElements = { false }
							fromHomePage = { true }
							isFollowing = { isFollowing }
							unfollowComponent = { (
								<div
									className = { classes.followComponent }
									onClick = { () => {
										handleGAEvent ( "unfollow" );
										if ( isMobile )
											setOpen ( false );
									} }
								>
									{/* <FeedElementFollowing className = { `${ classes.svgIcon } ` }
								id = "unfollow"
							/> */}

									<Icon
										sameInBothTheme
										className = { `${ classes.svgIcon } ` }
										name = "followingFilled"
									/>

									<Typography
										className = { classes.iconText }
										title = { from === "profile-header" ? "Unfollow Profile" : "Following" }
									/>

								</div>
							) }
							userId = { userId }
							userName = { userName }
						/>
					</div>

				) : null}

				{/* View Edit Profile option and Moderator List option in profile header when the user is viewing own profile  */}

				{( isOwnProfile && from === "profile-header" ) ? (
					<>

						<div
							className = { classes.menuItem }
							onClick = { () => {
								toggleMenuDialog ();
								toggleUserSettingsPopup ( true );
							} }
						>
							<Icon
								sameInBothTheme
								className = { classes.profileSvgIcon }
								name = "profileEdit"
							/>

							<Typography
								className = { classes.iconText }
								title = "Edit Profile"
							/>

						</div>

						<div
							className = { classes.menuItem }
							onClick = { () => getDonationSrc () }
						>
							<Icon
								sameInBothTheme
								className = { classes.profileSvgIcon }
								name = "profileGift"
							/>

							<Typography
								className = { classes.iconText }
								title = "Copy Donation Link"
							/>

						</div>

						<div
							className = { classes.menuItem }
							onClick = { e => {
								handleGAEvent ( "shareSelfProfile" );
								handleShareClickOpen ( e );
							} }
						>
							<Icon
								sameInBothTheme
								className = { classes.profileSvgIcon }
								name = "profileShare"
							/>

							<Typography
								className = { classes.iconText }
								title = { "Share" } // self profile share
							/>

						</div>

						<div
							className = { classes.menuItem }
							onClick = { () => {
								toggleMenuDialog ();
								toggleDialogInfoBox ( "2" );
							} }
						>
							<Icon
								sameInBothTheme
								className = { classes.profileSvgIcon }
								name = "profileMod"
							/>

							<Typography
								className = { classes.iconText }
								title = "View Stream Moderators"
							/>

						</div>
					</>

				) : null}

				{/* Add/Remove Moderator option in profile header when the user is viewing others profile  */}

				{( !isOwnProfile && from === "profile-header" ) ? (
					<>
						<a className = { classes.menuItem }
							href = { `${ DONATION_PAGE }?id=${ userProfileId }` }
							rel = "noreferrer"
							style = { { lineHeight: "0", textDecoration: "none" } }
							target = "_blank"
						>
							<Icon
								sameInBothTheme
								className = { `${ classes.profileSvgIcon } mr-2` }
								name = "profileGift"
							/>

							<Typography
								className = { classes.iconText }
								title = { "Donate" }
							/>

						</a>

						<div
							className = { classes.menuItem }
							onClick = { e => {
								handleGAEvent ( "shareOtherProfile" );
								// handleShareClickOpen ( e );
								toggleShareModal ( true );

							} }
						>
							<Icon
								sameInBothTheme
								className = { classes.profileSvgIcon }
								name = "profileShare"
							/>

							<Typography
								className = { classes.iconText }
								title = { "Share" }
							/>

						</div>

						<div className = { classes.menuItem }>
							<div className = { classes.moderatorContainer }
								onClick = { () => {
									toggleMenuDialog ();

									return isModerator ? removeModerator () : addModerator ();
								} }
							>
								<Icon
									sameInBothTheme
									className = { classes.profileSvgIcon }
									name = "profileMod"
								/>

								<Typography
									className = { classes.iconText }
									title = { isModerator ? "Remove Stream Moderator" : "Make Stream Moderator" }
								/>
							</div>

							<ToolTip
								arrow
								placement = "bottom-end"
								title = { <HoverInfoText /> }
							>
								<div className = { classes.hoverInfoText }>
									<Icon
										sameInBothTheme
										className = { `${ classes.profileInfoIcon } leftSpacing` }
										name = "profileInfo"
									/>
								</div>
							</ToolTip>

						</div>

						{isMobile ? cancelBtn : null}
					</>

				) : null}

				{/* Report option is not in profile header and for others profiles  */}

				{( !isOwnProfile && from !== "profile-header" ) ? (

					<div
						className = { classes.menuItem }
						onClick = { handleReport }
					>

						<Icon
							sameInBothTheme
							className = { classes.svgIcon }
							name = "reportFilled"
						/>

						<Typography
							className = { classes.iconText }
							title = "Report Post"
						/>

					</div>

				) : null}

				{from === "videoCard" && isMobile ? (
					cancelBtn
				)
					: null}

				{/* Share option */}

				{( ( from === "videoCard" && !isMobile ) || from === "results-page" ) ? (
					<div
						className = { classes.menuItem }
						onClick = { () => {
							handleGAEvent ( "share" );
							toggleShareModal ( true );
						} }
					>

						<Icon
							sameInBothTheme
							className = { classes.svgIcon }
							name = "shareFilled"
						/>

						<Typography
							className = { classes.iconText }
							title = "Share"
						/>

					</div>

				) : null}

				{/* Delete option is not in profile header and for own profile  */}

				{
					( isOwnProfile && from !== "profile-header" && activeTab !== "live" ) ? (
						<ConfirmModal
							closeAfterActionPreformed
							buttonComponent = { (
								<div
									className = { classes.menuItem }
								>
									<Icon
										sameInBothTheme
										className = { classes.svgIcon }
										name = "deleteFilled"
									/>

									<Typography
										className = { classes.iconText }
										title = "Delete Post"
									/>

								</div>
							) }
							dialogText = "This post will be permanently removed from your profile and this task can’t be undone"
							dialogTitle = "Delete Post?"
							from = "report-modal"
							logoutText = "Delete"
							performAction = { () => handleDelete () }
						/>
					)
						: null
				}

				{( isOwnProfile && from !== "profile-header" && activeTab === "live" && isLoggedIn ) ? (

					loading ? (
						<LoadingState from = { from }
							open = { open }
							status = { downloadStatus.status }
						/>
					) : (
						<>
							{/* <InfoModal
								buttonComponent = { (
									<div
										className = { classes.menuItem }
										onClick   = { onRequestToDownload }
									>
										<Icon
											className = { classes.svgIcon }
											name      = "downloadIcon"
										/>

										<Typography
											className = { classes.iconText }
											title     = { buttonText }
										/>

									</div>
								) }
								cancelIcon  = "cross"
								dialogText  = { infoModalText }
								dialogTitle = { infoModalTitle }
								from        = "report-modal"
								mainIcon    = "tickFilled"
								open        = { openDialog }
								setOpen     = { setOpenDialog }
							/> */}

							<ConfirmModal
								closeAfterActionPreformed
								buttonComponent = { (
									<div
										className = { classes.menuItem }
									>
										<Icon
											className = { classes.svgIcon }
											name = "delete"
										/>

										<Typography
											className = { classes.iconText }
											title = "Delete Post"
										/>

									</div>
								) }
								dialogText = "This post will be permanently removed from your profile and this task can’t be undone"
								dialogTitle = "Delete Post?"
								from = "report-modal"
								logoutText = "Delete"
								performAction = { () => handleDelete () }
							/>
						</>
					)

				) : null}
			</>
		);
	};

	const shareDialogue = showShareDialog ? (
		<SharePopper
			useModal
			anchorEl = { shareAnchorEl }
			getSrc = { getSrc }
			handleClose = { () => toggleShareModal ( false ) }
			open = { showShareDialog }
		/>
	) : null;

	if ( isMobile ) {
		return (
			<>
				<Icon
					sameInBothTheme
					className = { `${ classes.dotsIcon }` }
					name = "threeDotMenu"
					onClick = { handleOpen }
				/>

				<MobileBottomDrawer
					hidden = { showDialog }
					isOpen = { open || showDialog }
					onClose = { toggleMenuDialog }
				>
					<>

						<BackgroundWrapper className = { classes.popperStyle }>
							{renderOptions ()}
						</BackgroundWrapper>

						<ReportDialogBox
							feedId = { feedId }
							feedType = { feedType }
							setShowDialog = { setShowDialog }
							showDialog = { showDialog }
							updateFeedList = { updateFeedList }
							verticalFeedType = { verticalFeedType }
						/>

						{shareDialogue}

					</>
				</MobileBottomDrawer>
			</>
		);
	}

	return (
		<>
			{/* <div className = { classes.dotsIconWrapper }>
				<Icon
					className = { `${ classes.dotsIcon }` }
					name      = "menuDots"
					onClick   = { handleOpen }
				/>
			</div> */}

			<Menu
				classNames = { {
					root     : classes.popperWrapper,
					dropdown : classes.dropdown
				} }
				id = { feedId ? feedId : userId }
				onChange = { handleOpen }
				opened = { open }
				position = { from !== "reel" ? "left-start" : "top-start" }
				radius = "lg"
			>
				<Menu.Target>
					<div style = { { display: "flex", alignItems: "center", justifyContent: "center" } }>
						<Icon
							className = { `${ classes.dotsIcon }` }
							name = "menuDots"

						/>
					</div>
				</Menu.Target>

				<Menu.Dropdown>
					<div style = { { display: "flex", alignItems: "center", justifyContent: "center" } }>
						<BackgroundWrapper className = { classes.popperStyle }>
							{renderOptions ()}
						</BackgroundWrapper>
					</div>
				</Menu.Dropdown>
			</Menu>

			{
				showDialog && (
					<ReportDialogBox
						feedId = { feedId }
						feedType = { feedType }
						setShowDialog = { setShowDialog }
						showDialog = { showDialog }
						updateFeedList = { updateFeedList }
						verticalFeedType = { verticalFeedType }
					/>
				)
			}

			{
				shareModalOpen ? (
					<SharePopper
						useModal
						anchorEl = { shareAnchorEl }
						getSrc = { getSrc }
						handleClose = { handleClickAway }
						open = { shareModalOpen }
						placement = "bottom"
					/>
				)
					: null
			}

			{/* new share feature */}
			{shareDialogue}

		</>
	);
};

export default component;
