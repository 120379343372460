import Typography from "_components/atoms/typography";

import useStyles from "./styles";

const component = () => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<div className = { classes.mainContainer }>
			<Typography
				noWrap
				className = { classes.title }
				title     = "What are Stream Moderators?"
			/>

			<Typography
				className = { classes.content }
				title     = "Stream moderators help you manage your stream while you are streaming live."
			/>

			<Typography
				noWrap
				className = { `${ classes.title } topSpacing` }
				title     = "What are the benefits?"
			/>

			<Typography
				className = { classes.content }
				title     = "When you make some a moderator. They get access to special controls on your stream. These controls are:"
			/>

			<ol className = { classes.listContainer }
				type = "1"
			>
				<li className = { classes.listElement }>
					They can pin comment on your behalf.
				</li>

				<li className = { classes.listElement }>
					They can delete bad comments from the live chat.
				</li>

				<li className = { classes.listElement }>
					They can mute spammers from commenting.
				</li>

				<li className = { classes.listElement }>
					They can block people on your behalf for misbehaving.
				</li>

			</ol>
		</div>
	);
};

export default component;