import { Skeleton } from "@mantine/core";

export const ReportSkeleton = ( { customContainerStyle = {} } ) => {

	return (
		<div
			style = { customContainerStyle }
		>
			<Skeleton animation = "wave"
				height = { 60 }
				style = { { borderRadius: "10px", width: "120px", margin: "0 1rem 1rem 0" } }
				variant = "rectangular"
			/>
		</div>
	);
};